import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import jmjalogo from './homeimg/jmjalogo.webp';
import './navbar.css';
const Navbar = (props) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [activeLink, setActiveLink] = useState('/');

  useEffect(() => {
    // Update the active link when the location changes
    setActiveLink(location.pathname);
  }, [location.pathname]);


  useEffect(() => {
    // Handle window resize
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function toggleNavigation() {
    const hamburger = document.getElementById('hamburger');
    const hamburgerclose = document.getElementById('hamburgerclose');
    const navoption = document.getElementById('navoption');
    const navigation = document.getElementById('navigation');

    hamburger.classList.toggle('active');
    navoption.classList.toggle('active');

    if (hamburger.classList.contains('active')) {
      hamburger.style.display = 'none';
      hamburgerclose.style.display = 'block';
      navigation.style.height = '20rem'; // Adjust height as needed
      navoption.style.display = 'block';
    } else {
      hamburger.style.display = 'block';
      hamburgerclose.style.display = 'none';
      navoption.style.display = 'none';
      navigation.style.height = '8rem'; // Adjust height as needed
    }
  }

  return (
    <>
      <div className="whatsappicon">
        <a href="https://wa.me/8369372641"><i className="fa-brands fa-whatsapp"></i></a>
      </div>
      <div className="disclamer">
        {/* <p><span>Cel</span>ebrating 🎉 <span>10 ye</span>ars of <span>J</span>M<span>J</span>A <Link to="/celebrate">learn <span>More</span></Link></p> */}
      </div>
        <p style={{textAlign: 'center', marginTop: '1rem'}}>ICSI do not permit advertisement or solicitation, website is for information only. <Link to="/disclaimer">Learn More</Link></p>

      <div className="navigation" id="navigation">
        <div className="navitems">
          <div id="logo">
            <Link to="/"><img src={jmjalogo} alt="logo" /></Link>
          </div>
          <div className="navtabs" id="navitems">
            <ul id="navoption">
              <li className={`navlink ${activeLink === '/' ? 'active' : ''}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`navlink ${activeLink === '/about' ? 'active' : ''}`}>
                <Link to="/about">Know JMJA</Link>
              </li>
              <li className={`navlink ${activeLink === '/team' ? 'active' : ''}`}>
                <Link to="/team">Meet the Team</Link>
              </li>
              <li className={`navlink ${activeLink === '/services' ? 'active' : ''}`}>
                <Link to="/services">Services</Link>
              </li>
              {/* <li className={`navlink ${activeLink === '/gallery' ? 'active' : ''}`}>
                <Link to="/gallery  ">Gallery</Link>
              </li> */}
              <li className={`navlink ${activeLink === '/contact' ? 'active' : ''}`}>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>

            <div id="hamburger" className="material-symbols-outlined" onClick={toggleNavigation}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>

            <div id="hamburgerclose" className="material-symbols-outlined" onClick={toggleNavigation}>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
