import React from "react";
import { Link } from "react-router-dom";
import logo from "../navbar/homeimg/jmjalogo.webp";
import twitter from "./footerimg/twitter.webp";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footerimg">
          <img src={logo} alt="footerimg" />
        </div>

        <div className="footercontent">
          <div className="footer-about">
            <h2>About </h2>
            <p>
              JMJA : Your reliable partner for Corporate Secretarial, Compliance,
              and Governance services in India, ensuring seamless support for
              businesses with expertise, precision, and professionalism.
            </p>
            <div className="social">
              <h2>Connect with us</h2>
            </div>
            <div className="socialicons">
              <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=info@jmja.in">
                <i class="fa-solid fa-envelope"></i>
              </a>
              <a href="https://x.com/jmjallp" target="_blank">
                <img
                  src={twitter}
                  style={{ height: "18px", paddingRight: "4px" }}
                  alt="twitter"
                />
              </a>
              <a href="https://in.linkedin.com/company/jmjallp">
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://www.instagram.com/jmjallp/?hl=en">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="footer-links">
            <h2>Quick Links</h2>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link class="nav-footer-links " aria-current="page" to="/">
                  Home
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-footer-links " aria-current="page" to="/about">
                  Know JMJA
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-footer-links " aria-current="page" to="/team">
                  Meet the Team
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  class="nav-footer-links "
                  aria-current="page"
                  to="/services"
                >
                  Services
                </Link>
              </li>
{/* 
              <li class="nav-item">
                <Link
                  class="nav-footer-links "
                  aria-current="page"
                  to="/gallery"
                >
                  Gallery
                </Link>
              </li> */}

              <li class="nav-item">
                <Link
                  class="nav-footer-links "
                  aria-current="page"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
              {/* <li class="nav-item">
                <Link
                  class="nav-footer-links "
                  aria-current="page"
                  to="/terms&policy"
                >
                  Privacy Policy
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="adress">
            <h2>Address : </h2>
            <p>
              <i class="fa-solid fa-location-dot"></i>&nbsp;&nbsp;102, Accord
              Commercial Complex, Near Goregaon 
              Rly. Station, Goregaon (East), Mumbai-400063
            </p>
            <br />
            <p className="mobile">
              <a href="tel:+912240109730">
                <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;Tel. +91 22 4010
                9730
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="footerbottom">© JMJA and Associates LLP</div>
    </>
  );
};

export default Footer;
