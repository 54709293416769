import './App.css';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import About from './components/about/About';
import Bankrupt from './components/services/service/bankrupt/Bankrupt';
import Draft from './components/services/service/draft/Draft';
// import DueDelligence from './components/services/service/duedelligence/DueDelligence';
import Nbfc from './components/services/service/nbfc/Nbfc'
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Gift from './components/services/service/gift/Gift';
import Health from './components/services/service/health/Health';
import Orientation from './components/services/service/orientation/Orientation'
import Posh from './components/services/service/posh/Posh';
import Rbifema from './components/services/service/rbi&fema/Rbifema';
import Sebi from './components/services/service/sebi/Sebi';
import SecetrialServices from './components/services/service/secetrialServices/SecetrialServices';
import TransactionAdvisory from './components/services/service/transactionAdvisory/TransactionAdvisory';
import VirtualSecetrialServices from './components/services/service/virtualSectrialService/VirtualSecetrialServices';
import Disclaimer from './components/disclamer/Disclaimer';
import Celebrate from './components/celebrate/Celebrate';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './components/notfound/NotFound';
import Team from './components/team/Team';
import PolicyTerm from './components/terms&policy/PolicyTerm';
import Gallery from './components/gallery/Gallery';

function App() {
  return (
    <BrowserRouter>
         <ScrollToTop />
<Navbar/>
      <Routes>
        <Route exact path='/celebrate' element={<Celebrate/>}/>
        <Route exact path='/disclaimer' element={<Disclaimer/>}/>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/services" element={<Services />} />
          <Route exact path="/services/drafting" element={<Draft />} />
          <Route exact path="/services/bankrupt" element={<Bankrupt />} />
          {/* <Route path="/services/duedelligence" element={<DueDelligence />} /> */}
          <Route exact path="/services/gift" element={<Gift />} />
          <Route exact path="/services/health" element={<Health />} />
          <Route exact path="/services/nbfc" element={<Nbfc />} />
          <Route exact path="/services/orientation" element={<Orientation />} />
          <Route exact path="/services/posh" element={<Posh />} />
          <Route exact path="/services/rbi&fema" element={<Rbifema />} />
          <Route exact path="/services/sebi" element={<Sebi />} />
          <Route exact path="/services/secetrial-services" element={<SecetrialServices />} />
          <Route exact path="/services/transaction-advisory" element={<TransactionAdvisory />} />
          <Route exact path="/services/virtual-secetrial-services" element={<VirtualSecetrialServices />} />
          <Route exact path="/contact" element={<Contact />} />
          {/* <Route path="/gallery" element={<Gallery />} /> */}
          {/* <Route path='/terms&policy' element={<PolicyTerm/>}/> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
