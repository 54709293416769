import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import confetti from "canvas-confetti";
import "./home.css";
import introimg from "./homeimg/introimg.webp";
import companyincorporation from "./homeimg/companyincorporation.webp";
import startupadvisory from "./homeimg/startupadvisory.webp";
import ipoadvisory from "./homeimg/ipoadvisory.webp";
import secetrialaudit from "./homeimg/secetrialaudit.webp";
import drafting from "./homeimg/drafting.webp";
import business from "./homeimg/businessman_5337297.webp";

const Counter = ({ startValue = 0, endValue, duration }) => {
  const [value, setValue] = useState(startValue);

  useEffect(() => {
    const increment = () => {
      setValue((prev) => {
        if (prev >= endValue) {
          return endValue;
        }
        return prev + 1;
      });
    };

    const interval = setInterval(increment, duration);

    return () => clearInterval(interval);
  }, [endValue, duration]);

  return (
    <span style={{ color: "white", fontSize: "3.5vw", textAlign: "center" }}>
      {value}+
    </span>
  );
};

const Home = () => {
  const [modalVisible, setModalVisible] = useState(true);

  const counterData = [
    { endValue: 18, duration: 100 },
    { endValue: 25, duration: 100 },
    { startValue: 2800, endValue: 3000, duration: 2 },
  ];

  useEffect(() => {
    document.title = "Home";

    // Commented out the confetti effect functionality
    /*
    const duration = 8 * 1000; // 5 seconds
    const end = Date.now() + duration;

    (function frame() {
      confetti({
        particleCount: 3,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });
      confetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
    */

    // Hide the modal after 5 seconds
    setModalVisible(false);
    const hideModalTimeout = setTimeout(() => {
      setModalVisible(false);
    }, 8000);

    // Clean up the timeout
    return () => clearTimeout(hideModalTimeout);
  }, []);

  return (
    <>
      {/* {modalVisible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Celebrating 10 Years of Excellence – A Heartfelt Thank You!</h5>
              <button onClick={() => setModalVisible(false)} className="close-button">&times;</button>
            </div>
            <div className="modal-body">
              Dear Valued Clients, Partners, and Colleagues,
              <br />
              <br />
              We are thrilled to announce a significant milestone in our journey
              – JMJA has proudly completed 10 years of service! What began as a
              small dream has grown into a thriving firm, thanks to your
              unwavering trust and support.
              <br />
              <br />
              Over the past decade, we have strived to deliver excellence in
              corporate governance, compliance, and secretarial services,
              helping businesses navigate complexities with integrity and
              professionalism. This journey wouldn’t have been possible without
              your partnership, collaboration, and belief in our vision.
              <br /><br />
              As we look ahead to the future, we remain committed to evolving
              with the industry and continuing to provide you with the highest
              standards of service. We are excited to embark on the next chapter
              of growth, and we look forward to continuing this successful
              journey together.
              <br />
              <br />
              Thank you for being an integral part of our success!
            </div>
          </div>
        </div>
      )} */}

      <div className="mid">
        <div className="midcontent">
          <p className="second">Complicate law, let's uncomplicate</p>
          <p className="second">Consultancy | Advisory | Compliance</p>
        </div>
      </div>
      <div className="between">
        <div className="intro">
          <div className="introtop">About Us</div>
          <div className="introbox">
            <img src={introimg} alt="Introduction" />
            <div className="introboxcontent">
              <p>
                JMJA & Associates LLP (JMJA) is a research-based organization
                growing with an aim to provide right consultancy to the
                stakeholders for building up a strong Compliance System in a
                complex and ever-changing regulatory environment with
                flexibility and reliability. <br />
                <br />
                We are distinguished by our people; the partners have experience
                of nearly 2 decades of experience on Indian Corporate law. JMJA
                takes a new-age approach and provides stronger, more efficient
                services in the Legal and Secretarial field. With a headquarter
                in Mumbai and offices in multiple cities of India, we are
                serving clients PAN-India.
              </p>
              <button>
                <Link to="/about">Read More</Link>
              </button>
            </div>
          </div>
        </div>

        <div className="work">
          <div className="workupper">
            <h1>Our Expertise</h1>
          </div>
          <div className="workdown1">
            <div className="workcontent1">
              <div className="workitem1">
                <img src={companyincorporation} alt="Company Incorporation" />
                <p
                  className="itempupper"
                  style={{ fontWeight: 600, color: "#103c64" }}
                >
                  Setting Up a Business
                </p>
                <p>
                  We help businesses navigate the regulatory landscape and
                  ensure compliance with RBI and FEMA guidelines.
                </p>
                <button>
                  <Link to="/services/">Learn More</Link>
                </button>
              </div>
              <div className="workitem1">
                <img src={startupadvisory} alt="Startup Advisory" />
                <p
                  className="itempupper"
                  style={{ fontWeight: 600, color: "#103c64" }}
                >
                  Startup Services
                </p>
                <p>
                  We conduct Bank Specific, Compliance Specific, and other Due
                  Diligence services.
                </p>
                <button>
                  <Link to="/services/">Learn More</Link>
                </button>
              </div>
              <div className="workitem1">
                <img src={secetrialaudit} alt="Secretarial Audit" />
                <p
                  className="itempupper"
                  style={{ fontWeight: 600, color: "#103c64" }}
                >
                  IPO and Transaction Advisory
                </p>
                <p>
                  JMJA is actively involved in various transactions undertaken
                  by the corporates and...
                </p>
                <button>
                  <Link to="/services/transaction-advisory">Learn More</Link>
                </button>
              </div>
              <div className="workitem1">
                <img src={business} alt="Business" />
                <p
                  className="itempupper"
                  style={{ fontWeight: 600, color: "#103c64" }}
                >
                  FEMA – FDI and ODI Compliance
                </p>
                <p>
                  We help businesses navigate the regulatory landscape and
                  ensure compliance...
                </p>
                <button>
                  <Link to="/services/rbi&fema">Learn More</Link>
                </button>
              </div>
              <div className="workitem1">
                <img src={ipoadvisory} alt="IPO Advisory" />
                <p
                  className="itempupper"
                  style={{ fontWeight: 600, color: "#103c64" }}
                >
                  SEBI Compliance
                </p>
                <p>
                  Navigating the path to public success with expert advisory.
                </p>
                <button>
                  <Link to="/services/sebi">Learn More</Link>
                </button>
              </div>
              <div className="workitem1">
                <img src={drafting} alt="Drafting" />
                <p
                  className="itempupper"
                  style={{ fontWeight: 600, color: "#103c64" }}
                >
                  Drafting
                </p>
                <p>
                  We understand the importance of fostering a safe and
                  respectful work environment, and...
                </p>
                <button>
                  <Link to="/services/posh">Learn More</Link>
                </button>
              </div>
            </div>
          </div>

          <Link className="showmorework" to="/services">
            <button>
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </Link>
        </div>

        <div className="wrapper">
          <div className="wrappercontent">
            <i className="fa-solid fa-calendar-week"></i>
            <Counter {...counterData[0]} />
            <div className="text">Average Years of Experience</div>
          </div>

          <div className="wrappercontent">
            <i className="fa-solid fa-user-group"></i>
            <Counter {...counterData[1]} />
            <div className="text">Current Team Size</div>
          </div>

          <div className="wrappercontent">
            <i className="fa-solid fa-share-nodes"></i>
            <Counter {...counterData[2]} />
            <div className="text">Digital Connection</div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;
